import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Container from '../components/ui/Container'
import Heading from '../components/ui/Heading'
import Paragraph from '../components/ui/Paragraph'

const NotFoundPage = React.memo((props) => (
	<Layout location={props.location} noFooter={true} noExist={true}>
		<SEO title="404: Not found" />
		<Container
			style={{
				background: '#0C0C0C',
				height: '100vh',
				display: 'flex',
				alignItems: 'center',
			}}
		>
			<Heading lv={1}>404</Heading>
			<Paragraph style={{ fontWeight: 'bold' }}>
				We're sorry, it seems like the requested page could not be found or does
				not exist on this website.
			</Paragraph>
		</Container>
	</Layout>
))

export default NotFoundPage
